import JustValidate from "./just-validate.es.js";

(function (w, d) {

  function getFormValidations(formId) {

    const globalFormConfig = {
      errorFieldCssClass: 'invalid',
      errorFieldStyles: {
        border: '1px solid red'
      },
      errorLabelCssClass: 'invalid-label',
      errorLabelStyle: {
        color: '#ffffff'
      },
      successFieldStyles: {
        border: '1px solid green'
      },
      successLabelStyle: {
        color: '#ffffff'
      },
      lockForm: true,
      focusInvalidField: true,
    }

    if (formId === 'contact-form') {
      const validation = new JustValidate('#contact-form', globalFormConfig)

      validation.addField('#name', [
        {
          rule:'required',
          errorMessage:'Name is required'
        },
      ]).addField('#email', [
        {
          rule:'required',
          errorMessage:'Email is required'
        },
        {
          rule: 'email',
          errorMessage: 'Email is invalid!',
        }
      ]).addField('#budget', [
        {
          rule:'required',
          errorMessage:'Budget is required'
        },
      ]).addField('#phone', [
        {
          rule:'required',
          errorMessage:'Phone is required'
        },
      ]).addField('#file', [
        {
          rule: 'maxFilesCount',
          value: 1,
        },
        {
          rule:'files',
          value: {
            files: {
              maxSize: 2097152,
            }
          },
          errorMessage:'File is too large. Must be under 2Mb'
        },
        {
          rule:'files',
          value: {
            files: {
              extensions: ['pdf','doc','docx','odt','txt'],
            }
          },
          errorMessage:'File type is not acceptable. Must be a pdf, doc, docx, or odt'
        }
      ]).onSuccess( (event) => {
        processForm(event)
      } )

      return validation
    } else if (formId === 'hero-form') {

      const validation = new JustValidate('#hero-form', globalFormConfig)

      validation.addField('#name', [
        {
          rule:'required',
          errorMessage:'Name is required'
        },
      ]).addField('#email', [
        {
          rule:'required',
          errorMessage:'Email is required'
        },
        {
          rule: 'email',
          errorMessage: 'Email is invalid!',
        }
      ]).addField('#phone', [
        {
          rule:'required',
          errorMessage:'Phone is required'
        },
      ])

      return validation
    }
  }

  function processForm() {

    //disable form submission until a small amount of time to discourage form spam.
    setTimeout(() => {
      const submitButton = d.getElementById('formSend');

      submitButton.disabled = false;
    }, 1000)

    const form = d.querySelector('.contact-form')

    const formId = form.id;

    if (formId === 'contact-form') {
      fameDropdown()
      fameFileUpload()
    }

    const validation = getFormValidations(formId)

    validation.onSuccess( (event) => {
      processForm(event)
    })

    const minScore = 0.3
    let timeSpent = 0

    grecaptcha.ready(function() {
      grecaptcha.render('captchaEl', {
        'badge': 'inline',
        'sitekey': '6Lctw_kUAAAAAOkWZFxAS_TNUhz7edhS5ELjw6CJ',
        'size': 'invisible'
      });
    });

    form.addEventListener('submit', (e) => {
      e.preventDefault();
    })

    function processForm(e) {
      const currentForm = e.target;
      const submitButton = d.getElementById('formSend')

      currentForm.classList.add('processing');
      currentForm.querySelector('#formSend').disabled = true

      grecaptcha.ready(function () {

        grecaptcha.execute({
          action: 'fame_contact_form'
        }).then(function (token) {

          const captchaData = new FormData()

          captchaData.append('g-recaptcha-response', token)

          const captchaAction = currentForm.action.split("form")[0] + "form/captchaTest.php"

          fetch(captchaAction, {
            method: 'POST',
            body: captchaData
          }).then( response => {

            if (response.status !== 200) {
              throw 'Looks like there was a problem. Please try again later.'
            }

            return response.json()

          }).then(data => {

            if ('score' in data && data.score > minScore && data.success === true) {
              submitForm(currentForm, data.score)
            } else if (data.success === true && !('score' in data)) {
              console.error('reCaptcha response missing score : ' + JSON.stringify(data))
              submitForm(currentForm, null)
            } else if ('score' in data && data.score <= minScore && data.success === true) {
              decideFormMessage('spam', currentForm, data.score)
            } else {
              console.error('reCaptcha error : ' + JSON.stringify(data))
              decideFormMessage('Missing reCaptcha Response', currentForm, null)
            }

          })

        });
      });

    }

    function submitForm(currentForm, captchaScore) {

      const formData = new FormData(currentForm)
      formData.append('spamScore', captchaScore)

      fetch(currentForm.action, {
        method: 'POST',
        body: formData
      }).then(response => {

        if (response.status !== 200) {
          throw 'Looks like there was a problem. Please try again later.'
        }

        return response.text()

      }).then(data => {

        decideFormMessage(data, currentForm, captchaScore)

      })
    }

    let submitted = false;

    function decideFormMessage(result, currentForm, captchaScore) {

      if (result === 'sent') {
        displayFormMessage("sent", currentForm, captchaScore);
      } else if (result.includes('SMTP', 6)) {
        displayFormMessage('There was an issue with the mail server', currentForm, captchaScore)
      } else if (result === 'Missing reCaptcha Response') {
        displayFormMessage('Could not verify reCaptcha Results. Refresh the page to try again or call us to get in touch.', currentForm, captchaScore)
      } else if (result === 'spam') {
        displayFormMessage('Detected suspicious activity. Please call us to get in touch.', currentForm, captchaScore)
      } else {
        displayFormMessage('failed', currentForm, captchaScore);
      }

    }

    function displayFormMessage(verdict, currentForm, captchaScore = null) {

      const message = verdict === 'failed' ? 'There was an error, please try again.' :
        verdict === 'sent' ? 'Message sent successfully! You will hear from us soon.' :
          verdict;

      let formResultClass = "formResult "

      currentForm.classList.remove('processing');
      currentForm.querySelector('#formSend').removeAttribute('disabled')

      if (captchaScore !== null && captchaScore < minScore) {

        sendGTM('Form Spam')

        formResultClass += 'formSuccess'
        currentForm.reset();
        submitted = true;

      } else if (verdict === 'sent') {

        sendGTM('Form Success')

        formResultClass += 'formSuccess'
        currentForm.reset();
        submitted = true;

      } else if (verdict === 'failed') {

        sendGTM('Form Failure')
        formResultClass += 'formFail'

      } else {

        formResultClass += 'formFail'

      }

      function sendGTM(eventName) {

        if (typeof dataLayer === 'undefined') return

        dataLayer.push({
          'event':eventName,
          'verdict': verdict,
          'timeSpent': timeSpent,
          'captchaScore': captchaScore
        });
      }

      if (submitted === false) {
        currentForm.insertAdjacentHTML('beforeend' ,'<div class="' + formResultClass + '">' + message + '</div>');
      } else {
        currentForm.innerHTML = '<div class="' + formResultClass + '">' + message + '</div>';
      }

    }

  }

  function fameFileUpload() {

    const fileInput = d.getElementById('file'),
      fileLabel = d.querySelector('[for="file"]')
    ;

    fileInput.addEventListener('change', function (e) {
      fileLabel.textContent = e.target.files[0].name;
    });
  }

  function fameDropdown() {

    const dropdown = d.getElementsByClassName("contact-dropdown");

    for (var i = 0; i < dropdown.length; i++) {

      const selElmnt = dropdown[i].getElementsByTagName("select")[0];
      const selectLabel = d.createElement("DIV");

      selectLabel.setAttribute("class", "select-selected");
      selectLabel.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      dropdown[i].appendChild(selectLabel);

      const itemsWrapper = d.createElement("DIV");

      itemsWrapper.setAttribute("class", "select-items select-hide");

      for (let j = 1; j < selElmnt.length; j++) {

        const selectItem = d.createElement("DIV");
        selectItem.innerHTML = selElmnt.options[j].innerHTML;

        selectItem.addEventListener("click", function (e) {

          let match, selInput, selLabel;
          selInput = this.parentNode.parentNode.getElementsByTagName("select")[0];
          selLabel = this.parentNode.previousSibling;

          for (var i = 0; i < selInput.length; i++) {
            if (selInput.options[i].innerHTML == this.innerHTML) {
              selInput.selectedIndex = i;
              selLabel.innerHTML = this.innerHTML;
              match = this.parentNode.getElementsByClassName("same-as-selected");
              for (let k = 0; k < match.length; k++) {
                match[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }

          selLabel.click();
        });

        itemsWrapper.appendChild(selectItem);
      }

      dropdown[i].appendChild(itemsWrapper);
      selectLabel.addEventListener("click", function (e) {
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
      });

    }

    function closeAllSelect(elmnt) {

      let arrNo = [];
      const itemsWrap = d.getElementsByClassName("select-items");
      const selLabel = d.getElementsByClassName("select-selected");
      for (var i = 0; i < selLabel.length; i++) {
        if (elmnt == selLabel[i]) {
          arrNo.push(i)
        } else {
          selLabel[i].classList.remove("select-arrow-active");
        }
      }
      for (i = 0; i < itemsWrap.length; i++) {
        if (arrNo.indexOf(i)) {
          itemsWrap[i].classList.add("select-hide");
        }
      }
    }

    d.addEventListener("click", closeAllSelect);
  }

  w.addEventListener('load', () => {
    processForm()
  })

})(window, document);